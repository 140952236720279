<template>

    <form v-if="form" class="rounded-lg w-full bg-white dark:bg-gray-750 px-4 py-4 sm:px-6 mb-3">

        <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="col-span-1">
                <Input type="date" placeholder="Rēķina datums" v-model="form.invoice_date" :errors="errors.invoice_date"/>
            </div>

            <div class="col-span-1">
              <Input type="date" placeholder="Apmaksas datums" v-model="form.payment_date" :errors="errors.payment_date"/>
            </div>
        </div>

        <div class="grid grid-cols-1 pt-4">
            <div class="col-span-1">
                <Input placeholder="Klienta juridiskā adrese" v-model="form.invoice_address" :errors="errors.invoice_address"/>
            </div>
        </div>

        <div class="grid grid-cols-1 pt-4">
            <div class="col-span-1">
                <Input placeholder="Piegādes adrese" v-model="form.invoice_delivery_address" :errors="errors.invoice_delivery_address"/>
            </div>
        </div>

        <div class="grid grid-cols-2 pt-4">
            <div class="col-span-1">
                <Checkbox class="md:mt-8" text="Sagatavots elektroniski" v-model="form.electronic_document"/>
            </div>

            <div class="col-span-1">
                <Radio label="Valoda:" name="lang" v-model="form.lang"
                       :items="[
                  { name: 'LV', value: 'lv' },
                  { name: 'ENG', value: 'en' },
                ]"
                       :errors="errors.lang"
                />
            </div>
        </div>

        <div class="pt-5">
            <div class="flex justify-end">
                <button @click="hideAddForm" type="button"
                        class="bg-white dark:bg-gray-750 py-2 px-4 border border-gray-300 dark:border-gray-500 rounded-md shadow-sm text-sm font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-770 focus:outline-none focus:ring-0 mr-2">
                    Atcelt
                </button>

                <template v-if="!loading">
                    <button type="button"
                            class="flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-gradient-to-r from-button2-from to-button2-to hover:from-button2-to hover:to-button2-from focus:outline-none focus:border-none transition duration-500 ease-in-out"
                            @click.prevent="submit">
                        Saglabāt
                    </button>
                </template>
                <template v-else>
                    <Loading/>
                </template>
            </div>
        </div>
    </form>
</template>

<script>
import {mapGetters} from "vuex";
import Input from "@/components/Components/Input";
import Validation from "@/modules/Validation";
import Loading from "@/components/Components/Loading";
import Checkbox from "../../Components/Checkbox";
import Radio from "../../Components/Radio";

export default {
    name: "EditOrderInvoice",
    components: {
        Input,
        Loading,
        Checkbox,
        Radio,
    },
    props: {
        order: {
            type: Object,
            required: true,
        }
    },
    data: () => ({
        form: null,
    }),
    mounted() {
        this.form = this.order.invoice
        this.form.lang = this.order.lang
    },
    computed: {
        ...mapGetters({
            loading: "loading",
            errors: "errors",
        }),
        formValidation() {
            return {
                invoice_date: {
                    rules: ['required']
                },
            }
        }
    },
    methods: {
        hideAddForm() {
            this.$store.dispatch("removeAllFormsForDisplay");
        },
        submit() {
            this.$Progress.start();
            if (Validation(this.formValidation, this.form)) {
                this.$store.dispatch("updateOrUpdateOrderInvoice", {
                    data: {
                        invoice_date: this.form.invoice_date,
                        payment_date: this.form.payment_date,
                        invoice_address: this.form.invoice_address,
                        invoice_delivery_address: this.form.invoice_delivery_address,
                        electronic_document: this.form.electronic_document,
                        lang: this.form.lang,
                    },
                    order_id: this.order.id,
                });
            } else {
                this.$Progress.fail();
            }
        },
    },
}
</script>
